
import echars from "@/utils/echarts";
import * as echarts from 'echarts';
import kriging from "@/utils/kriging";

/**
 * peak：极值 {min：-10，max：50}
 * typeName： 要素key
 */
export function ColorLabelKey(peak, typeName) {
  var arr = {
    wendu: [
      // 温度
      // { min: -60, max: -32, color: "#7C0072" },
      // { min: -32, max: -28, color: "#800081" },
      // { min: -28, max: -24, color: "#510070" },
      // { min: -24, max: -20, color: "#161365" },

      // ---  动态色斑图1  ---- 
      // { min: -20, max: -16, color: "#2B47AC" },
      // { min: -16, max: -12, color: "#5665D2" },
      // { min: -12, max: -8, color: "#46A0ED" },
      // { min: -8, max: -4, color: "#83DDF9" },
      // { min: -4, max: 0, color: "#B2E7EC" },
      // { min: 0, max: 4, color: "#F8FFCB" },
      // { min: 4, max: 8, color: "#D4FFAE" },
      // { min: 8, max: 12, color: "#B1FE79" },
      // { min: 12, max: 16, color: "#F5FF60" },
      // { min: 16, max: 20, color: "#F5C95C" },
      // { min: 20, max: 24, color: "#F98256" },
      // { min: 24, max: 28, color: "#F94D63" },
      // { min: 28, max: 32, color: "#FF3223" },
      // { min: 32, max: 50, color: "#A72012" }
      // --- 动态结束添加1 ---
      
      // ---  动态色斑图2  ---- 
      // {color: '#2B47AC'},
      // {color:'#5665D2'},
      // {color:'#46A0ED'},
      // {color:'#82BAC9'},
      // {color:'#9EE1BE'},
      // {color:'#C4F490'},
      // {color:'#E7E35B'},
      // {color:'#FDC238'},
      // {color:'#FE9D36'},
      // {color:'#FF7739'},
      // {color:'#FE5337'},
      // --- 动态结束添加2 ---
      
      // 温度
      { min: -20, max: -12, color: "#082F7C" },
      { min: -12, max: -8, color: "#1C5C9D" },
      { min: -8, max: -4, color: "#2075CD" },
      { min: -4, max: -0, color: "#3BA0F0" },
      { min: 0, max: 4, color: "#83D0FE" },
      { min: 4, max: 8, color: "#A9E7F6" },
      { min: 8, max: 12, color: "#D2FBFD" },
      { min: 12, max: 16, color: "#F4FDEE" },
      { min: 16, max: 20, color: "#CCFFCC" },
      { min: 20, max: 24, color: "#BFFD8D" },
      { min: 24, max: 28, color: "#FCFC9D" },
      { min: 28, max: 32, color: "#FEF2C2" },
      { min: 32, max: 35, color: "#FFCCA1" },
      { min: 35, max: 37, color: "#F99584" },
      { min: 37, max: 40, color: "#FC5504" },
      { min: 40, max: 90, color: "#FC4243", str:'>' },
      // { min: 43, max: 90, color: "#E90001" },
    ],
    
    jiangshui: [
      // 短时降水
      { min: 0, max: 0.1, color: "#FFFFFF" },
      { min: 0.1, max: 10, color: "#9DF495" },
      { min: 10, max: 25, color: "#23BA4A" },
      { min: 25, max: 50, color: "#65B3FC" },
      { min: 50, max: 100, color: "#3000D8" },
      { min: 100, max: 250, color: "#FF00F4" },
      { min: 250, max: 350, color: "#83003E" },
    ],
    jiangxue: [
      // 降雪
      { min: 0, max: 0.1, color: "#fff" },
      { min: 0.1, max: 2.5, color: "#CCCCCC" },
      { min: 2.5, max: 5.5, color: "#A1A1A1" },
      { min: 5.5, max: 10, color: "#707070" },
      { min: 10, max: 20, color: "#454545" },
      { min: 20, max: 30, color: "#343434" },
      { min: 30, max: 999, color: "#010101" },
    ],
    fsfx:[
      { min: 0, max: 2, color: "#FCFCFD" },
      { min: 2, max: 4, color: "#65FF52" },
      { min: 4, max: 6, color: "#C8FF5E" },
      { min: 6, max: 8, color: "#F6FF5B" },
      { min: 8, max: 10, color: "#F1C74D" },
      { min: 10, max: 12, color: "#F88838" },
      { min: 12, max: 14, color: "#F65728" },
      { min: 14, max: 16, color: "#FF3020" },
      { min: 16, max: 18, color: "#CB5E22" },
      { min: 18, max: 20, color: "#841403" },
      { min: 20, max: 50, color: "#841403" },
    ],
    fsfx1:[
      { min: 0, max: 4, color: "#FFFFFF" },
      { min: 4, max: 5, color: "#97D2CA" },
      { min: 5, max: 6, color: "#9CBADC" },
      { min: 6, max: 7, color: "#6F99DE" },
      { min: 7, max: 8, color: "#4577D7" },
      { min: 8, max: 9, color: "#170F75" },
      { min: 9, max: 10, color: "#4B017E" },
      { min: 10, max: 11, color: "#8A0086" },
      { min: 11, max: 12, color: "#CD00A5" },
      { min: 12, max: 999, color: "#CD00A5" },
    ],
    shidu: [
      // 湿度
      { min: 0, max: 10, color: "#2D177B" },
      { min: 10, max: 20, color: "#FA6A2E" },
      { min: 20, max: 30, color: "#F8ED87" },
      { min: 30, max: 40, color: "#F9F4BA" },
      { min: 40, max: 50, color: "#CEF8F0" },
      { min: 50, max: 60, color: "#74E3EC" },
      { min: 60, max: 70, color: "#3F7FE2" },
      { min: 70, max: 80, color: "#7FB8D7" },
      { min: 80, max: 90, color: "#3D41E3" },
      { min: 90, max: 100, color: "#4106C9" },
      // { min: 100, max: 200, color: "#2D177B" }
    ],
    njd:[
      { min: 0, max: 0.2, color: "#6D2C07" },
      { min: 0.2, max: 0.5, color: "#AB00F5" },
      { min: 0.5, max: 1, color: "#F9321F" },
      { min: 1, max: 2, color: "#FF5D2D" },
      { min: 2, max: 3, color: "#F7B363" },
      { min: 3, max: 5, color: "#EEFF5F" },
      { min: 5, max: 10, color: "#87FC5F" },
      { min: 10, max: 20, color: "#95E2F3" },
      { min: 20, max: 100, color: "#CEEAF7" },
    ],
    qiya: [
      // 气压
      { min: 850, max: 900, color: "#3D7CC6" },
      { min: 900, max: 910, color: "#4CBA88" },
      { min: 910, max: 920, color: "#6CD150" },
      { min: 920, max: 930, color: "#D3E839" },
      { min: 930, max: 940, color: "#EBC637" },
      { min: 940, max: 960, color: "#E98D44" },
      { min: 960, max: 980, color: "#DA4570" },
      { min: 980, max: 1000, color: "#A82358" },
      { min: 1000, max: 1100, color: "#6E152E" },
      { min: 1100, max: 3000, color: "#2B0001" }
    ]
  };

  const ConfigureColorCards = () => {
    let { min, max } = peak;
    if(min - max === 0)return arr[typeName];
    let list = arr[typeName];
    let average = (max - min) / list.length;
    let interval = Number(average.toFixed(1));
    // console.log('peak', peak, 'interval', interval)
    if(interval<1) interval = interval + 1
    let arrs = [];
    /***最高温度缓冲 */
    // let maxArr = [
    //   {color:'#FE9D36'},
    //   {color:'#FF7739'},
    //   {color:'#FE5337'},
    // ]
    list.reverse().forEach((it,index) => {
      let obj = {}
      if (index === 0) {
          obj.max = max;
          obj.min = max - interval;
      } else {  
          let minas = arrs[arrs.length - 1].min;
          obj.max = minas;
          obj.min = obj.max - interval;
      }
      obj.color = it.color;
      obj.max = Number(obj.max.toFixed(1));
      obj.min = Number(obj.min.toFixed(1));
      arrs.push(obj)
    });
    /***最高温度缓冲 */
    // maxArr.forEach((it, index) => {
    //   let { max, min } = arrs[0];
    //   if (index == maxArr.length - 1) {
    //       arrs.unshift({ max: max + interval*2, min: min + interval, color: it.color })
    //   } else {
    //     arrs.unshift({ max: max + interval, min: min + interval, color: it.color })
    //   }
    // })

    // arrs[0].min = arrs[0].min + interval; 
    arrs[0].max = arrs[0].max + interval * 10; // 最高温度阈值加大区间
    arrs[arrs.length-1].min = arrs[arrs.length-1].min - interval * 2;
    arrs[arrs.length - 1].max = arrs[arrs.length - 1].max - interval * 2;
    return arrs
  }
  
  let colorList = arr[typeName];
  if (typeName == 'wendu') {
    // colorList = ConfigureColorCards();
    colorList = arr[typeName].reverse();
    // console.log(colorList)
  }
  return colorList
}



// key：{wendu[],jiangshui:[],...}
// 获取色斑图的色标卡
export function getColorGuide(peak, key) {
  let arr = ColorLabelKey(peak, key);
  let colorList = AnalyzeColor(arr);
  return colorList
}

/**
 * 通过要素差值 创建色标卡阈值
 * list: [{ min: -60, max: -32, color: "#7C0072" }] // 要素差
 */
export function AnalyzeColor(list) {
  let section = []; // [-16, -12, -8, -6, -4, -2]
  let color = []; // [[0, 0, 255], [0, 40, 255]]
  for (let i = 0; i < list.length; i++) {
    const element = list[i];
    section.push(element.min);
    color.push(colorRgb(element.color).arr)
    if (list.length - 1 === i) {
      section.push(element.max);
    }
  }
  return {section,color}
}
/**
 * // 16进制颜色转换rgb格式
 * str:#7C0072
 */
function colorRgb (str){
  var reg = /^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/
  if(!reg.test(str)){return;}
  let newStr = (str.toLowerCase()).replace(/\#/g,'')
  let len = newStr.length;
  if(len == 3){
    let t = ''
    for(var i=0;i<len;i++){
     t += newStr.slice(i,i+1).concat(newStr.slice(i,i+1))
    }
    newStr = t
  }
  let arr = [];
  for(var i =0;i<6;i=i+2){
    let s = newStr.slice(i,i+2)
    arr.push(parseInt("0x" + s))
  }
  return { rgb: 'rgb(' + arr.join(",") + ')', arr };
};


// 经纬度 比例计算
/***
 * scope{   minlng: 113.46539037726474, maxlng: 115.48357359296629, minlat: 36.04820207889178, maxlat: 37.01507440960651}
 * ratio：宽度 默认 2000
 */
function  SameExample(scope, ratio=2000){
  const { maxlng, maxlat, minlng, minlat } = scope;
  let x = maxlng - minlng; // width
  let y = maxlat - minlat; // height
  let width = ratio;
  let height = width / (x / y) 
  return {width, height}
}

/**
 * @param {*} viewer  // 地图实例
 * @param {*} stationData  // 要素数据
 * @param {*} scoure  // 面边界数据
 * @param {*} typeKey  // 字段里的key
 * @param {*} typeName  // 色斑图类型
 */

// 色斑图转换  克里金
export function loadkriging(stationData, scoure, typeName, scope, peak) {
  var canvas = document.getElementById("canvasMap");
  var size = SameExample(scope, 300)
  canvas.width = size.width;
  canvas.height = size.height;
  var n = stationData.length;
  var t = [];
  var x = [];
  var y = [];
  var coords = [];
  var data_scoure = [];

  for (var i = 0; i < n; i++) {
    t.push(parseFloat(stationData[i].data));
    // 经纬度点
    x.push(stationData[i].Lon); // x
    y.push(stationData[i].Lat); // y
  }

  for (var i = 0; i < scoure.features.length; i++) {
    scoure.features[i].geometry.coordinates[0];
    data_scoure.push(scoure.features[i].geometry.coordinates[0]);
  }
  
  data_scoure = [data_scoure[0][0]];
  for (let item of scoure.features[0].geometry.coordinates[0]) {
    for (var i = 0; i < item.length; i++) {
      for (var j = 0; j < item[i].length; j++) {
        coords.push(item[i][j]);
      }
    }
  }

  function drawKriging(lats, lngs, values, coords, ex) {
    let { minlng:minx, maxlng:maxx, minlat:miny, maxlat:maxy } = scope;
    let colors = ColorLabelKey(peak, typeName);
    // console.log('colors',colors)
    let canvas = null; // 画布
    var size = SameExample(scope, 300)
    function getCanvas() {
      //1.用克里金训练一个variogram对象；
      // 'exponential':指数  'gaussian':高斯,'spherical':球体
      let variogram = kriging.train(values, lngs, lats, "exponential", 0, 0.1);
      //2.使用刚才的variogram对象使polygons描述的地理位置内的格网元素具备不一样的预测值；
      // (maxy - miny) / 500的500是返回的网格数量,越大越细处理越慢。
      // console.log(variogram);
      console.time('kriging.grid');
      let grid = kriging.grid(ex, variogram, (maxy - miny) / 300);
      console.timeEnd('kriging.grid'); // 测试方法耗时时间
      canvas = document.createElement("canvas");
      canvas.width = size.width;
      canvas.height = size.height;
      canvas.style.display = "block";
      canvas.getContext("2d").globalAlpha = 1; //设置透明度
      //3.将得到的格网预测值渲染到canvas画布上

      kriging.plot(canvas, grid, [minx, maxx], [miny, maxy], colors);
    }
    getCanvas();
    var imgBaes64 = canvas.toDataURL('image/png');
    // console.log(imgBaes64)
    return imgBaes64
  }
  var img = drawKriging(y, x, t, coords, data_scoure);
  return img
}





// Drawdispose(dom){

// }

export function drawEcLine(dom){
  let myChart = echarts.init(dom);
  // const geoJson = require('@/assets/1qqhr县界.json');
  let yjJson = require('@/assets/qqhr县界.json');
  // yjJson.features.forEach(Feature => {
  //   Feature.geometry.coordinates[0] = [Feature.geometry.coordinates[0]];
  // })
  echarts.registerMap('yjJson', yjJson);
  var option = {
    grid: {
      left: 0,
      right: 0,
      bottom: 0,
      top:0,
    },
    geo: {
      map: "yjJson",
      itemStyle: {
        normal: {
            areaColor: 'rgba(128, 128, 128, 0)', //rgba设置透明度0
            borderColor: '#000',//省份边框颜色
            borderWidth:0.1,//省份边框
        },
        emphasis: {
            areaColor: '#0f2c70'
        }
      },
    },
    series: [
      {
        type: 'geo',
        map: 'yjJson',
        label: {
          show: true
        },
        // 自定义名称映射
        nameMap: {
          
        }
      }
    ]
  }
  myChart.setOption(option);
}



export function drawMerarLine(dom,data,xData,dw,name,site) {
  // console.log(dw,name,site) 
  let echar = echars.init(dom);
  // 设置配置项
  let option = {
    title: {
      text: site,
      textStyle: {
        color:'#fff'
      }
    },
    grid: {
      left: '3%',
      right: '3%',
      bottom: '3%',
      containLabel: true
    },
    tooltip: {
      trigger: 'axis',
      formatter:function(val,index){
        // console.log(val)
          let obj = val[0]
         return`${site}<br/>${obj.marker} ${obj.seriesName}：${obj.data}${dw}`
      }
    },
    xAxis: {
      type: 'category',
      data: xData,
      axisLine: {
        lineStyle: {
          color: '#fff',//轴线颜色
          // color: 'rgba(255,255,255,.5)',//轴线颜色
        }
      },
    },
    yAxis: {
      name:name,
      type: 'value',
      splitLine: {
        lineStyle: {
          // color: ['#aaa', '#ddd'],// 使用深浅的间隔色
          color: 'rgba(255,255,255,.5)',//轴线颜色
        }
      },
      nameTextStyle:{
        color:'#fff'
      },
      axisLabel: {
        fontSize: 10,
        formatter: '{value}'+dw,
        textStyle: {//改变xy轴上文字的颜色
          color: "#fff"
        }
      }
    },
    dataZoom: [
        //给x轴设置滚动条
        {
          height: 8,
          start: 0, //默认为0
          end: 100 - 1500 / 31, //默认为100
          type: "slider",
          show: true,
          xAxisIndex: [0],
          handleSize: 0, //滑动条的 左右2个滑动条的大小
          bottom: "0%",
          left: "90%", //左边的距离
          right: "90%",
          borderColor: "#000",
          fillerColor: "#01DFA5",
          borderRadius: 5,
          backgroundColor: "#33384b", //两边未选中的滑动条区域的颜色
          showDataShadow: false, //是否显示数据阴影 默认auto
          showDetail: false, //即拖拽时候是否显示详细数值信息 默认true
          realtime: true, //是否实时更新
          filterMode: "filter",
        },
        {
          type: "inside",
          show: true,
          xAxisIndex: [0],
          start: 0, //默认为1
          end: 100 - 1500 / 31, //默认为100
        },
    ],
    series: [
      {
        type: "line",
        name:name,
        data: data,
        itemStyle: {
          normal: {
            lineStyle: {
              color: "#61B8FF"//折线的颜色
            }
          }
        }
      },
    ],
  };
  echar.setOption(option);
}